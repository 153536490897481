<template>
  <v-app class="dashboard-main">
    <v-navigation-drawer
        v-model="drawer"
        app
    >
      <v-list-item class="nav-logo app-bar-header">
          <img src="../assets/logo.png"/>
      </v-list-item>

      <v-divider></v-divider>
      <v-list class="dashboard-main-menu">
        <router-link :to="`/admin`">
          <v-list-item>
            <v-list-item-icon>
              <v-icon>mdi-laptop</v-icon>
            </v-list-item-icon>
            <v-list-item-title>Dashboard</v-list-item-title>
          </v-list-item>
        </router-link>
        <v-list-group
            v-for="item in items"
            :key="item.title"
            v-model="item.active"
            :prepend-icon="item.action"
            no-action
        >
          <template v-slot:activator>
              <v-list-item-content>
                <v-list-item-title v-text="item.title"></v-list-item-title>
              </v-list-item-content>
          </template>

          <v-list-item
              v-for="child in item.items"
              :key="child.title"
          >
            <router-link :to="`/admin/${child.link}`">
              <v-list-item-content>
                <v-list-item-title v-text="child.title"></v-list-item-title>
              </v-list-item-content>
            </router-link>
          </v-list-item>
        </v-list-group>
      </v-list>
    </v-navigation-drawer>

    <v-app-bar app class="app-bar-header">
      <v-app-bar-nav-icon color="white" @click="drawer = !drawer"></v-app-bar-nav-icon>
      <v-spacer></v-spacer>
      <v-menu offset-y style="width: 300px;">
        <template v-slot:activator="{ on, attrs }">
          <v-list
              style="background-color: transparent;"
              dark
          >
            <v-list-item
                class="avatar-container"
                v-bind="attrs"
                v-on="on"
            >
              <v-list-item-avatar>
                <v-avatar>
                  <img
                      :src="adminImageSrc"
                      alt="Admin Avatar"
                  >
                </v-avatar>
              </v-list-item-avatar>
              <v-list-item-content>
                <v-list-item-title>
                  {{ adminName }}
                  <v-icon small>mdi-chevron-down</v-icon>
                </v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </v-list>
        </template>
        <v-list dense>
          <v-subheader>Welcome!</v-subheader>
          <v-list-item-group>
            <v-list-item @click="logout()">
              <v-list-item-icon>
                <v-icon>mdi-power</v-icon>
              </v-list-item-icon>
              <v-list-item-content>
                <v-list-item-title>Logout</v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </v-list-item-group>
        </v-list>
      </v-menu>
    </v-app-bar>

    <!-- Sizes your content based upon application components -->
    <v-main class="dashboard-v-main">

      <!-- Provides the application the proper gutter -->
      <v-container fluid>

        <!-- If using vue-router -->
        <router-view></router-view>
      </v-container>
    </v-main>

    <v-footer app>
      <!-- -->
    </v-footer>
  </v-app>
</template>

<script>

import router from '@/router';

export default ({
  name: 'DashboardView',
  data: () => ({
    drawer: null,
    adminName: '',
    adminImageSrc: '',
    items: [
      {
        action: 'mdi-account-group-outline',
        items: [
          { title: 'Add', link: 'add-user' },
          { title: 'Manage', link: 'manage-users' },
        ],
        title: 'Users',
      },
      {
        action: 'mdi-briefcase-outline',
        items: [
          { title: 'Add', link: 'add-candidate' },
          { title: 'Manage', link: 'manage-candidates' },
          { title: 'Add Types', link: 'add-candidate-types' },
          { title: 'Manage Types', link: 'manage-candidate-types' },
        ],
        title: 'Candidates',
      },
      {
        action: 'mdi-chart-box-outline',
        items: [
          { title: 'Add', link: 'add-party' },
          { title: 'Manage', link: 'manage-parties' },
        ],
        title: 'Parties',
      },
      {
        action: 'mdi-cube-outline',
        items: [
          { title: 'Add', link: 'add-topic' },
          { title: 'Manage', link: 'manage-topics' },
        ],
        title: 'Topics',
      },
      {
        action: 'mdi-newspaper-variant-multiple',
        items: [
          { title: 'Add', link: 'add-article' },
          { title: 'Manage', link: 'manage-articles' },
        ],
        title: 'Articles',
      },
      {
        action: 'mdi-tag',
        items: [
          { title: 'Add', link: 'add-question' },
          { title: 'Manage', link: 'manage-questions' },
        ],
        title: 'Questions',
      },
    ],
  }),
  mounted() {
    this.adminName = 'Admin';
    this.adminImageSrc = 'http://api.thepovnews.com/admin_asset/images/users/avatar-4.jpg';
  },
  methods: {
    logout() {
      this.$store.commit('LOGOUT');
      router.push({ name: 'login' });
    }
  },
});
</script>

<style lang="scss">
  .dashboard-main {
    font-family: "Lato", sans-serif;
  }
  .nav-logo {
    background-color: #ff9800 !important;
    box-shadow: 0px 2px 4px -1px rgb(0 0 0 / 20%), 0px 4px 5px 0px rgb(0 0 0 / 14%), 0px 1px 10px 0px rgb(0 0 0 / 12%);

  img {
      height: 63px;
      width: auto;
      flex: unset;
      padding: 5px;
      margin: auto;
    }
  }
  .avatar-container {
    .v-list-item__title, .v-list-item__title i {
      color: #e5e5e5;
    }
  }
  .dashboard-main-menu {
    .v-list-item__title, a {
      color: #797979;
      text-decoration: none;
    }
  }
  .dashboard-v-main {
    padding: 30px 70px 50px !important;
    background-color: #f1f1f1;
    color: #797979 !important;
  }
  .app-bar-header {
    background: linear-gradient(to top, #FA9140, #FA9140);
  }
</style>
